export default [
  {
    header: 'การจัดการ',
  },
  // {
  //   title: 'กระดานรายงาน',
  //   route: 'dashboard-ecommerce',
  //   icon: 'ClipboardIcon',
  // },
  {
    title: 'แดชบอร์ด',
    route: 'Dashboard',
    icon: 'ClipboardIcon',
  },
  {
    title: 'จัดการผู้ใช้งาน',
    route: 'Account',
    icon: 'UserIcon',
  },
  {
    title: 'จัดการธุรกรรม',
    route: 'Transaction',
    icon: 'DollarSignIcon',
  },
  {
    title: 'จัดการแอดมิน',
    route: 'Admin',
    icon: 'UsersIcon',
  },
  {
    title: 'จัดการเอเจนต์',
    route: 'Agent',
    icon: 'CopyIcon',
  },
  {
    title: 'จัดการบัญชีธนาคาร',
    route: 'Bank',
    icon: 'CreditCardIcon',
  },
  {
    title: 'ตั้งค่าระบบ',
    route: 'Setting',
    icon: 'SettingsIcon',
  },
]
