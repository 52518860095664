<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.username }}
        </p>
        <!-- <span class="user-status">0 ฿</span> -->
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.username"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>ตั้งค่าบัญชีผู้ใช้</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>บัญชีธนาคาร</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="DollarSignIcon"
        class="mr-50"
      />
      <span>เติมเงิน</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="InfoIcon"
        class="mr-50"
      />
      <span>วิธีใช้งาน</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>ศูนย์ช่วยเหลือ</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import store from '@/store'
// import { getCredit } from '@core/utils/utils'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    // formatValue() {
    //   const value = store.state.appLottery.credit
    //   const val = parseFloat(value).toFixed(2)
    //   return new Intl.NumberFormat().format(val)
    // },
  },
  created() {
    // getCredit()
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      this.$store.commit('auth/SET_LOGIN', false)

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'Login' })
    },
    // getCredit() {
    //   const uid = localStorage.getItem('userId')
    //   const promise = new Promise((resolve, reject) => {
    //     try {
    //       const result = this.$store.dispatch('appLottery/getCredit', {
    //         uid,
    //       })
    //       resolve(result)
    //     } catch (error) {
    //       reject(error)
    //     }
    //   })

    //   promise.then(result => {
    //     if (result.data.success) {
    //       localStorage.setItem('credit', result.data.credit.$numberDecimal)
    //       this.credit = result.data.credit.$numberDecimal
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
  },
}
</script>
